<template>
    <div class="cont">
        <el-row class="scene-top">
            <el-link class="back_iconfont" style="width: 40px;" type="primary" icon="iconfont iconfanhui1" @click="go_back()"></el-link>
            <!-- <div class="scene-box">
              <span v-text="$t('msg.new_scene_explain_txt')">触发设备、被执行设备会根据设备位置、设备类型进行查找，可能会匹配到一个及以上的设备。建议配置完成后，进入详情查看，不需要加入到场景的设备，可以在编辑功能里去掉该设备。</span>
            </div> -->
        </el-row>
        <!-- 场景照片 -->
        <div class="det-box">
            <el-divider content-position="left"><span v-text="$t('msg.scene_photo')">场景照片</span></el-divider>
            <el-row class="p-left-60" style="display: flex" v-if="refresh">
               <img width="148" height="148" style="margin-right: 10px" class="old-pic" v-if="oldPicShow" :src="oldPicUrl" alt="">
               <el-upload
                       ref="upload"
                       :limit="1"
                       :show-file-list="true"
                       action="#"
                       :auto-upload="false"
                       list-type="picture-card"
                       :on-change="handleChange"
                       :on-preview="handlePictureCardPreview">
                  <i class="el-icon-plus"></i>
               </el-upload>
               <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
               </el-dialog>
            </el-row>
        </div>
        <!-- 场景信息 -->
        <div class="det-box">
            <el-divider content-position="left"><span v-text="$t('msg.scene_info')">场景信息</span></el-divider>
            <ul>
                <li>
                    <span v-text="$t('msg.own_hotel_a')">所属酒店：</span>
                    <el-select v-if="addShow" class="width-193" v-model="hotelVal" @change="hotel_change" :placeholder="$t('msg.select')"  clearable>
                        <el-option v-for="item in hotelOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span v-else v-text="hotelName"></span>
                </li>
                <li>
                    <span v-text="$t('msg.dev_buildUnit_a')">设备所属楼栋/单元：</span>
                    <!-- <span v-text="agreementVal">—</span> -->
                    <el-cascader
                            v-if="addShow"
                            v-model="buildUnitVal"
                            :options="buildingList"
                            @change="buildingChange"
                            clearable
                            class="width-193">
                    </el-cascader>
                    <span v-else v-text="buildUnitName"></span>
                </li>
                <li>
                    <span v-text="$t('msg.room_type_a')">房间类型：</span>
                    <el-select v-if="addShow"  class="width-193" v-model="dRoomTypeVal" @change="roomTypeChange" :placeholder="$t('msg.select')" clearable>
                        <el-option v-for="item in dRoomTypeOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <span v-else v-text="dRoomTypeName"></span>
                </li>
                <li>
                    <span v-text="$t('msg.scene_mode_a')">场景模式：</span>
                    <el-input class="width-193" v-model="dSceneModel" :placeholder="$t('msg.scene_mode')"  clearable></el-input>
                </li>
                <li>
                    <span v-if="!addShow" v-text="$t('msg.belong_floorRoom_a')">所属楼层/房间：</span>
                    <span v-if="addShow" v-text="$t('msg.room_num_a')">房间号：</span>
                    <el-select v-if="addShow" class="width-193" v-model="roomVal" @change="roomChange" :placeholder="$t('msg.select')"  clearable>
                        <el-option v-for="item in dEditRoomNoOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <p v-else style="display: inline-block;">
                        <span v-text="floorName"></span>
                        <span v-if="floorName !== '—'" v-text="$t('msg.floors')">层</span>
                        <span v-text="'/'"></span>
                        <span v-text="roomName"></span>
                    </p>
                </li>
                <li>
                    <span v-text="$t('msg.effective_a')">是否生效：</span>
                    <el-switch
                            v-model="dSwitchVal"
                            active-value="VALID"
                            inactive-value="INVALID">
                    </el-switch>
                </li>
                <li class="active">
                    <span v-text="$t('msg.oper_status_a')">操作状态：</span>
                    <el-radio-group v-model="sceneAction" @change="sceneChange">
                        <el-radio v-for="(item,index) in sceneActionOpt" :key="index" :label="item.value" :disabled="!addShow">
                            <span v-text="item.label"></span>
                        </el-radio>
                    </el-radio-group>
                </li>
                <li></li>
                <li>
                    <span v-text="$t('msg.scene_off_time_a')">场景关闭时间：</span>
                    <el-select class="width-193" v-model="dSceneTimeVal" :placeholder="$t('msg.select')"  clearable>
                        <el-option v-for="item in dSceneTimeOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </li>
                <li>
                    <span v-text="$t('msg.scene_off_delayed_a')">场景延时时间：</span>
                    <el-select class="width-193" v-model="dSceneDelayVal" :placeholder="$t('msg.select')"  clearable>
                        <el-option v-for="item in dSceneDelayOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </li>
                <li></li>
                <li>
                    <span v-text="$t('msg.whe_applets_a')">是否同步小程序：</span>
                    <el-switch
                            v-model="appletsVal"
                            @change="syncSceneChange('WECHAT',1,appletsVal)"
                            :active-value="true"
                            :inactive-value="false">
                    </el-switch>
                </li>
                <li>
                    <span v-text="$t('msg.whe_voice_a')">是否同步语音：</span>
                    <el-switch
                            v-model="voiceVal"
                            @change="syncSceneChange('VOICE',2,voiceVal)"
                            :active-value="true"
                            :inactive-value="false">
                    </el-switch>
                </li>
            </ul>
            <div class="remarks-span">
                <span v-text="$t('msg.remarks_a')">备注信息：</span>
                <el-input type="textarea" v-model="dRemarkVal" :placeholder="$t('msg.remarks')"></el-input>
            </div>
        </div>
        <el-row v-if="!addShow" class="row-buttom row-padding">
            <el-button class="bg-gradient" type="primary" @click="add_scenes()" v-text="$t('msg.save')">保存</el-button>
        </el-row>
        <div class="det-box">
            <el-divider content-position="left"><span v-text="$t('msg.execute_dev')">执行设备</span></el-divider>
            <div class="execute">
                <div class="execute-room">
                    <div class="top-text"><span v-text="$t('msg.room_dev_list')">房间设备列表</span></div>
                    <el-table :data="executeData" border @selection-change="handleSelectionChange" style="width: 100%;" max-height="780" :stripe="true" >
                        <!-- <el-table-column prop="number" :label="$t('msg.number')" width="70"></el-table-column> -->
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="deviceName" :label="$t('msg.dev_number')" min-width="140"></el-table-column>
                        <el-table-column prop="versionsName" :label="$t('msg.dev_name')"></el-table-column>
                        <el-table-column prop="alias" :label="$t('msg.alias')">
                            <template slot-scope="scope">
                                <p v-if="scope.row.switchAlias">
                                    <span v-text="scope.row.switchAlias"></span>
                                    <span v-text="'('"></span>
                                    <span v-if="scope.row.deviceIndex === 0" v-text="$t('msg.first')"></span>
                                    <span v-if="scope.row.deviceIndex === 1" v-text="$t('msg.second')"></span>
                                    <span v-if="scope.row.deviceIndex === 2" v-text="$t('msg.third')"></span>
                                    <span v-if="scope.row.deviceIndex === 3" v-text="$t('msg.fourth')"></span>
                                    <span v-text="')'"></span>
                                </p>
                                <span v-else v-text="scope.row.alias"></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="location" :label="$t('msg.location')"></el-table-column>
                    </el-table>
                </div>
                <div>
                    <el-row class="m-bottom-30 execute-tag">
                        <div class="top-text"><span v-text="$t('msg.select_tg_dev')">已选触发设备</span></div>
                        <el-table :data="triggerData" border empty-text=" " style="width: 100%;min-height: 300px;" max-height="780" :stripe="true" >
                            <el-table-column prop="deviceName" :label="$t('msg.dev_number')" min-width="140"></el-table-column>
                            <el-table-column prop="versionsName" :label="$t('msg.dev_name')" min-width="100"></el-table-column>
                            <el-table-column prop="alias" :label="$t('msg.alias')">
                                <template slot-scope="scope">
                                    <p v-if="scope.row.switchAlias">
                                        <span v-text="scope.row.switchAlias"></span>
                                        <span v-text="'('"></span>
                                        <span v-if="scope.row.deviceIndex === 0" v-text="$t('msg.first')"></span>
                                        <span v-if="scope.row.deviceIndex === 1" v-text="$t('msg.second')"></span>
                                        <span v-if="scope.row.deviceIndex === 2" v-text="$t('msg.third')"></span>
                                        <span v-if="scope.row.deviceIndex === 3" v-text="$t('msg.fourth')"></span>
                                        <span v-text="')'"></span>
                                    </p>
                                    <span v-else v-text="scope.row.alias"></span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="location" :label="$t('msg.location')"></el-table-column>
                            <el-table-column prop="sceneAction" :label="$t('msg.status')" min-width="120">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.sceneAction === 'TURNON'" v-text="$t('msg.open_s')">开</span>
                                    <span v-if="scope.row.sceneAction === 'TURNOFF'" v-text="$t('msg.close_s')">关</span>
                                    <span v-if="scope.row.sceneAction === 'TURNONOFF'" v-text="$t('msg.open_close_s')">开,关</span>
                                    <el-radio-group v-if="scope.row.sceneAction === 'CUSTIZE'" v-model="scope.row.auto" @change="autoAction(scope.row,scope.$index,1)">
                                        <el-radio label="TURNON"><span v-text="$t('msg.open_s')">开</span></el-radio>
                                        <el-radio label="TURNOFF"><span v-text="$t('msg.close_s')">关</span></el-radio>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('msg.operating')" min-width="90" fixed="right">
                                <template slot-scope="scope">
                                    <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="remove(scope,1)" >
                                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <p class="arrow"><i class="el-icon-d-arrow-right" @click="addDevice(1)"></i></p>
                    </el-row>
                    <el-row class="execute-tag">
                        <div class="top-text"><span v-text="$t('msg.select_tger_dev')">已选被触发设备</span></div>
                        <el-table :data="targetData" border empty-text=" " style="width: 100%;min-height: 300px;" max-height="780" :stripe="true" >
                            <el-table-column prop="deviceName" :label="$t('msg.dev_number')" min-width="140"></el-table-column>
                            <el-table-column prop="versionsName" :label="$t('msg.dev_name')" min-width="100"></el-table-column>
                            <el-table-column prop="alias" :label="$t('msg.alias')">
                                <template slot-scope="scope">
                                    <p v-if="scope.row.switchAlias">
                                        <span v-text="scope.row.switchAlias"></span>
                                        <span v-text="'('"></span>
                                        <span v-if="scope.row.deviceIndex === 0" v-text="$t('msg.first')"></span>
                                        <span v-if="scope.row.deviceIndex === 1" v-text="$t('msg.second')"></span>
                                        <span v-if="scope.row.deviceIndex === 2" v-text="$t('msg.third')"></span>
                                        <span v-if="scope.row.deviceIndex === 3" v-text="$t('msg.fourth')"></span>
                                        <span v-text="')'"></span>
                                    </p>
                                    <span v-else v-text="scope.row.alias"></span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="location" :label="$t('msg.location')"></el-table-column>
                            <el-table-column prop="sceneAction" :label="$t('msg.status')" min-width="120">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.sceneAction === 'TURNON'" v-text="$t('msg.open_s')">开</span>
                                    <span v-if="scope.row.sceneAction === 'TURNOFF'" v-text="$t('msg.close_s')">关</span>
                                    <span v-if="scope.row.sceneAction === 'TURNONOFF'" v-text="$t('msg.open_close_s')">开,关</span>
                                    <el-radio-group v-if="scope.row.sceneAction === 'CUSTIZE'" v-model="scope.row.auto" @change="autoAction(scope.row,scope.$index,1)">
                                        <el-radio label="TURNON"><span v-text="$t('msg.open_s')">开</span></el-radio>
                                        <el-radio label="TURNOFF"><span v-text="$t('msg.close_s')">关</span></el-radio>
                                    </el-radio-group>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('msg.operating')" min-width="90" fixed="right">
                                <template slot-scope="scope">
                                    <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="remove(scope,2)" >
                                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <p class="arrow"><i class="el-icon-d-arrow-right" @click="addDevice(2)"></i></p>
                    </el-row>
                </div>
            </div>
        </div>
        <el-row v-if="addShow" class="row-buttom">
            <el-button class="bg-gradient" type="primary" @click="add_scenes()" v-text="$t('msg.save')">保存</el-button>
        </el-row>
    </div>
</template>
<script>
   import { urlObj } from '@/api/interface'
   import common from '@/common/js/common'
   export default {
      data () {
         return {
            oldPicShow: false,    // 旧图显示/隐藏
            oldPicUrl: '',        // 旧图地址
            dialogImageUrl: '',   // 放大图地址
            dialogVisible: false, // 放大图显示/隐藏
            refresh: true,        // 刷新组件

            dev_24_status: '设备24小时状态图',
            sel_time_range: '请选择时间范围！',
            no_state: '暂无状态！',
            confirm: '确定',
            online_status_no_data: '设备24小时一直处于在线状态，无状态数据！',
            offline_status_no_data: '设备24小时一直处于离线状态，无状态数据！',
            open_s: '开',
            close_s: '关',
            yes: '是',
            no: '否',
            positive: '正',
            anti: '反',
            normal: '正常',
            add_success: '添加成功！',
            update_success: '修改成功！',
            remove_success: '移除成功！',
            sync_success: '同步成功！',
            cancel_sync_success: '取消同步成功！',
            prompt: '提示！',
            sel_trigger_device: '请选择触发设备！',
            sel_execute_device: '请选择执行设备！',
            scene_model_no_null: '场景模式不能为空！',
            trigger_device_no_null: '触发设备不能为空！',
            execute_device_no_null: '执行设备不能为空！',
            remark_len_no_300: '备注字数不能超过300！',
            total: 0, // 表格数据总数
            pageSize: 0, // 每页显示数
            currPage: 1, // 当前页
            deviceTypeId: '', // 场景id
            addShow: true, // 新增还是编辑
            sceneAction: 'TURNON', // 操作状态
            sceneActionOpt: [
               {
                  value: 'TURNON',
                  label: '开'
               },
               {
                  value: 'TURNOFF',
                  label: '关'
               },
               {
                  value: 'TURNONOFF',
                  label: '开,关'
               },
               {
                  value: 'CUSTIZE',
                  label: '自定义'
               }
            ], // 操作状态
            roomNoVal: '', // 房间号
            hotelVal: '', // 酒店id
            hotelName: '—', // 酒店名称
            dRoomTypeVal: '', // 房间类型
            dRoomTypeName: '—', // 房间名称
            dSceneModel: '', // 场景模式
            dSwitchVal: 'VALID', // 是否生效
            dSceneTimeVal: '', // 场景关闭时间
            dSceneDelayVal: '', // 场景延时时间
            appletsId: '', // 小程序id
            appletsVal: true, // 是否同步小程序
            voiceId: '', // 语音id
            voiceVal: true, // 是否同步语音
            dRemarkVal: '', // 备注
            hotelOpt: [],
            dRoomTypeOpt: [],
            dSceneTimeOpt: [
               {
                  value: '0',
                  label: '不用关闭'
               },
               {
                  value: '10',
                  label: '10秒'
               },
               {
                  value: '20',
                  label: '20秒'
               },
               {
                  value: '30',
                  label: '30秒'
               },
               {
                  value: '40',
                  label: '40秒'
               },
               {
                  value: '50',
                  label: '50秒'
               },
               {
                  value: '60',
                  label: '60秒'
               },
               {
                  value: '70',
                  label: '70秒'
               },
               {
                  value: '80',
                  label: '80秒'
               },
               {
                  value: '90',
                  label: '90秒'
               },
               {
                  value: '100',
                  label: '100秒'
               },
               {
                  value: '110',
                  label: '110秒'
               },
               {
                  value: '120',
                  label: '120秒'
               }
            ],
            dSceneDelayOpt: [
               {
                  value: '0',
                  label: '不用延时'
               },
               {
                  value: '1',
                  label: '1秒'
               },
               {
                  value: '2',
                  label: '2秒'
               },
               {
                  value: '3',
                  label: '3秒'
               },
               {
                  value: '4',
                  label: '4秒'
               },
               {
                  value: '5',
                  label: '5秒'
               },
               {
                  value: '6',
                  label: '6秒'
               },
               {
                  value: '7',
                  label: '7秒'
               },
               {
                  value: '8',
                  label: '8秒'
               },
               {
                  value: '9',
                  label: '9秒'
               },
               {
                  value: '10',
                  label: '10秒'
               },
               {
                  value: '11',
                  label: '11秒'
               },
               {
                  value: '12',
                  label: '12秒'
               },
               {
                  value: '13',
                  label: '13秒'
               },
               {
                  value: '14',
                  label: '14秒'
               },
               {
                  value: '15',
                  label: '15秒'
               },
               {
                  value: '16',
                  label: '16秒'
               },
               {
                  value: '17',
                  label: '17秒'
               },
               {
                  value: '18',
                  label: '18秒'
               },
               {
                  value: '19',
                  label: '19秒'
               },
               {
                  value: '20',
                  label: '20秒'
               },
               {
                  value: '21',
                  label: '21秒'
               },
               {
                  value: '22',
                  label: '22秒'
               },
               {
                  value: '23',
                  label: '23秒'
               },
               {
                  value: '24',
                  label: '24秒'
               },
               {
                  value: '25',
                  label: '25秒'
               },
               {
                  value: '26',
                  label: '26秒'
               },
               {
                  value: '27',
                  label: '27秒'
               },
               {
                  value: '28',
                  label: '28秒'
               },
               {
                  value: '29',
                  label: '29秒'
               },
               {
                  value: '30',
                  label: '30秒'
               }
            ],
            executeData: [], // 执行设备
            triggerData: [], // 已选触发设备
            targetData: [], // 已选被触发设备
            buildUnitVal: '', // 设备所属楼栋/单元
            buildUnitName: '—', // 所属楼栋/单元名称
            roomVal: '', // 所属楼层/房间
            floorName: '—', // 楼层名称
            roomName: '—', // 房间名称
            dEditRoomNoOpt: [],
            imageUrl: '', // 酒店logo
            floorVal: '', // 楼层
            buildingUnit: '', // 楼栋/单元(表头)
            buildingList: [],
            deviceChange: []
         }
      },
      mounted () {
         this.pageSize = sessionStorage.getItem('pageSize') * 1 // 每页显示数
         this.isChildren = sessionStorage.getItem('childDetail') // 是否是子设备
         this.deviceTypeId = this.$route.query.id
         if (this.deviceTypeId) {
            this.addShow = false
            this.scene_info(this.deviceTypeId) // 场景信息
         } else {
            this.hotel_list() // 酒店(下拉框)
         }
      },
      methods: {
         // 获取文件资源
         handleChange(file){
            // 文件类型效验
            const imgFormat = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif'
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!imgFormat) {
               this.$message.error('上传头像图片只能是 JPG/PNG/GIF 格式!');
               // 强制刷新局部DOM节点
               this.refresh = false
               this.$nextTick(() => {
                  this.refresh = true
               })
               return
            }
            if (!isLt2M) {
               this.$message.error('上传头像图片大小不能超过 2MB!');
               this.refresh = false
               this.$nextTick(() => {
                  this.refresh = true
               })
               return
            }
            this.oldPic = false
            this.file = file.raw
         },
         // 处理放大图片
         handlePictureCardPreview(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
         },

         pageChange (v) {
            this.pageSize = v // 每页显示
            this.defaults.limit = this.pageSize
            this.more_status(0, 1)
         },
         currChange (v) { // 分页点击
            this.currPage = v // 当前页
            this.defaults.page = this.currPage
            this.more_status(0, 1)
         },
         // 返回(场景配置)
         go_back () {
            this.$router.push('/hotel_scenes_config?type=1')
         },
         // 多选事件
         handleSelectionChange (val) {
            this.deviceChange = val
         },
         // 场景信息
         scene_info (id) {
            let detUrl = urlObj.sceneDetail
            let detParam = {
               sceneId: id
            }
            this.$axios.get(detUrl, detParam).then((res) => {
               if (res.success) {
                  let obj = res.records
                  this.hotelName = this.$route.query.hotelName // 所属酒店
                  this.buildUnitName = obj.buildUnitName
                  this.dRoomTypeName = obj.roomTypeName
                  this.roomVal = obj.roomId
                  this.floorName = obj.floorNo
                  this.roomName = obj.roomNo
                  this.dSceneModel = obj.sceneName // 场景模式名称
                  this.dSwitchVal = obj.state // 是否生效
                  this.sceneAction = obj.sceneAction // 操作状态
                  if (obj.sceneSyncList) {
                     let index1 = obj.sceneSyncList.findIndex(item => item.deviceType === 'WECHAT')
                     let index2 = obj.sceneSyncList.findIndex(item => item.deviceType === 'VOICE')
                     if (index1 != -1) {
                        this.appletsId = obj.sceneSyncList[index1].id
                        this.appletsVal = true
                     } else {
                        this.appletsVal = false
                     }
                     if (index2 != -1) {
                        this.voiceId = obj.sceneSyncList[index2].id
                        this.voiceVal = true
                     } else {
                        this.voiceVal = false
                     }
                  } else {
                     this.appletsVal = false
                     this.voiceVal = false
                  }
                  this.dSceneTimeVal = obj.delayExecuteTime.split('|')[0] // 场景关闭时间
                  this.dSceneDelayVal = obj.delayExecuteTime.split('|')[1] // 场景延时时间
                  this.dRemarkVal = obj.remark // 备注信息
                  this.device_list(obj.triggerDeviceList,obj.targetDeviceList)
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 场景同步到小程序,语音
         syncSceneChange (deviceType,val,state){
            // console.log(val)
            if (!this.addShow) {
               let delUrl = ''
               let delParam = {
                  // sceneId: this.deviceTypeId, // 场景ID
                  // deviceType: deviceType // 同步类型
               }
               if (val === 1 && this.appletsVal || val === 2 && this.voiceVal) {
                  delUrl = urlObj.sceneSynctSceneSync
                  delParam = {
                     sceneId: this.deviceTypeId, // 场景ID
                     deviceType: deviceType // 同步类型
                  }
               } else {
                  if (val === 1) {
                     delParam.sceneSyncId = this.appletsId // 小程序的id
                  } else {
                     delParam.sceneSyncId = this.voiceId // 语音的id
                  }
                  delUrl = urlObj.deleteSceneSyncById
               }
               this.$axios.get(delUrl, delParam).then((res) => {
                  if (res.success) {
                     let records = res.records
                     if (val === 1) {
                        this.appletsId = records // 小程序的id
                     } else {
                        this.voiceId = records // 语音的id
                     }
                     this.$message({
                        showClose: true,
                        message: state?this.sync_success:this.cancel_sync_success,
                        type: 'success'
                     })
                  }
               }).catch(error => {
                  console.log(error)
               })
            }
         },
         // 操作状态修改
         sceneChange (val){
            this.device_list(this.triggerData,this.targetData,1)
            if (this.executeData.length > 0) {
               this.executeData.map(item => item.sceneAction = this.sceneAction)
            }
         },
         autoAction(row,index,type){
            if (!this.addShow) {
               let delUrl = urlObj.updateSceneDevice
               let delParam = {
                  id: row.id, // 场景ID
                  deviceAction: row.sceneAction
               }
               if (row.sceneAction === 'CUSTIZE') {
                  delParam.deviceAction = row.auto
               }
               this.$axios.post(delUrl, delParam).then((res) => {
                  if (res.success) {
                     // console.log(res)
                     this.$message({
                        showClose: true,
                        message: this.update_success,
                        type: 'success'
                     })

                  }
               }).catch(error => {
                  if (row.sceneAction === 'CUSTIZE') {
                     if (row.auto === 'TURNON') {
                        if (type === 1) {
                           this.triggerData[index].auto = 'TURNOFF'
                        } else {
                           this.targetData[index].auto = 'TURNOFF'
                        }
                     } else {
                        if (type === 1) {
                           this.triggerData[index].auto = 'TURNON'
                        } else {
                           this.targetData[index].auto = 'TURNON'
                        }
                     }
                  }
                  console.log(error)
               })
            }
            // this.triggerData = JSON.parse(JSON.stringify(this.triggerData))
         },
         // 设备列表
         device_list (triggerDeviceList,targetDeviceList,index){
            if (!triggerDeviceList || triggerDeviceList.length <= 0) {
               this.triggerData = []
            } else {
               let tempTriggerArr = []
               let tempTriggerObj = {}
               for (let k = 0; k < triggerDeviceList.length; k++) {
                  if (index) {
                     tempTriggerObj.index = triggerDeviceList[k].index
                  }
                  tempTriggerObj.id = triggerDeviceList[k].id
                  tempTriggerObj.deviceId = triggerDeviceList[k].deviceId
                  tempTriggerObj.deviceIndex = triggerDeviceList[k].deviceIndex
                  tempTriggerObj.deviceName = triggerDeviceList[k].deviceName
                  tempTriggerObj.location = triggerDeviceList[k].location
                  tempTriggerObj.versionsName = triggerDeviceList[k].versionsName
                  tempTriggerObj.alias = triggerDeviceList[k].alias
                  tempTriggerObj.switchAlias = triggerDeviceList[k].switchAlias
                  tempTriggerObj.sceneAction = this.sceneAction
                  tempTriggerObj.auto = 'TURNON'
                  if (this.sceneAction === 'CUSTIZE' && triggerDeviceList[k].deviceAction) {
                     tempTriggerObj.auto = triggerDeviceList[k].deviceAction
                  }
                  tempTriggerArr.push(tempTriggerObj)
                  tempTriggerObj = {}
               }
               this.triggerData = tempTriggerArr // 触发设备
            }
            if (!targetDeviceList || targetDeviceList.length <= 0) {
               this.targetData = []
            } else {
               let tempExecuteArr = []
               let tempExecuteObj = {}
               for (let k = 0; k < targetDeviceList.length; k++) {
                  if (index) {
                     tempExecuteObj.index = targetDeviceList[k].index
                  }
                  tempExecuteObj.id = targetDeviceList[k].id
                  tempExecuteObj.deviceId = targetDeviceList[k].deviceId
                  tempExecuteObj.deviceIndex = targetDeviceList[k].deviceIndex
                  tempExecuteObj.deviceName = targetDeviceList[k].deviceName
                  tempExecuteObj.location = targetDeviceList[k].location
                  tempExecuteObj.versionsName = targetDeviceList[k].versionsName
                  tempExecuteObj.alias = targetDeviceList[k].alias
                  tempExecuteObj.switchAlias = targetDeviceList[k].switchAlias
                  tempExecuteObj.sceneAction = this.sceneAction
                  tempExecuteObj.auto = 'TURNON'
                  if (this.sceneAction === 'CUSTIZE' && targetDeviceList[k].deviceAction) {
                     tempExecuteObj.auto = targetDeviceList[k].deviceAction
                  }
                  tempExecuteArr.push(tempExecuteObj)
                  tempExecuteObj = {}
               }
               this.targetData = tempExecuteArr // 被触发设备
               // this.$forceUpdate()
               if (!index) {
                  this.get_tigger_add(this.triggerData,this.targetData,1)
               }
            }
         },
         // 酒店(下拉框)
         hotel_list () {
            let hotUrl = urlObj.getHotelList
            let hotParam = {}
            this.$axios.post(hotUrl, hotParam, 'json').then((res) => {
               if (res.success) {
                  // console.log(res)
                  let dataObj = res.records
                  if (!dataObj || dataObj.length <= 0) {
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < dataObj.length; i++) {
                     tempObj.value = dataObj[i].id
                     tempObj.label = dataObj[i].hotelName
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.hotelOpt = tempArr // 酒店下拉框(表头)
                  // this.hotelVal = dataObj[0].id
                  // this.floor_list() // 楼层下拉框(表头)
                  // this.room_list() // 房间号(表头)
                  // this.scene_list() // 场景列表
                  // this.get_room(this.hotelVal) // 获取房间类型
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 酒店改变
         hotel_change () {
            this.buildUnitVal = ''
            this.dRoomTypeVal = ''
            this.roomVal = ''
            this.dRoomTypeOpt = []
            this.get_room() // 获取房间类型
            this.building_list()
         },
         // 获取楼栋单元层级
         building_list () {
            let storeUrl = urlObj.buildAndUnitList + `/${this.hotelVal}`
            let storeParam = {}
            this.$axios.get(storeUrl, storeParam).then((res) => {
               if (res.success) {
                  let records = res.records
                  if (!records || records.length <= 0) {
                     this.buildingList = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (let i = 0; i < records.length; i++) {
                     tempObj.value = records[i].id
                     tempObj.label = records[i].buildName
                     let children = []
                     let childObj = {}
                     for (let j = 0; j < records[i].buildUnits.length; j++) {
                        childObj.value = records[i].buildUnits[j].id
                        childObj.label = records[i].buildUnits[j].unitName
                        children.push(childObj)
                        childObj = {}
                     }
                     tempObj.children = children
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.buildingList = tempArr
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取楼栋单元层级
         buildingChange (val) {
            // console.log(val)
            this.roomVal = ''
            if(val.length > 0) {
               this.room_list()
            }
         },
         // 房型下的房间
         roomTypeChange () {
            // console.log(val)
            this.roomVal = ''
            this.room_list()
         },
         // 房间选择
         roomChange () {
            this.executeData = []
            this.deviceChange = []
            this.triggerData = []
            this.targetData = []
            this.get_tigger_add(this.triggerData,this.targetData)
         },
         // 获取房间类型
         get_room () {
            let rooUrl = urlObj.getHotelRoomTypeList
            let rooParam = {
               hotelId: this.hotelVal
            }
            this.$axios.post(rooUrl, rooParam).then((res) => {
               if (res.success) {
                  let dataObj = res.records
                  if (!res.records || dataObj.length <= 0) {
                     this.dRoomTypeOpt = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < dataObj.length; i++) {
                     // tempObj.value = dataObj[i].code
                     tempObj.value = dataObj[i].roomTypeId
                     tempObj.label = dataObj[i].roomTypeName
                     tempArr.push(tempObj)
                     tempObj = {}
                  }

                  this.dRoomTypeOpt = tempArr // 房间类型下拉框
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 房间号下拉框()
         room_list () {
            let rooUrl = urlObj.getRoomLists
            let rooParam = {
               hotelId: this.hotelVal, // 酒店ID
               roomTypeId: this.dRoomTypeVal, // 房间类型
               buildUnitId: this.buildUnitVal[1]
            }
            this.$axios.post(rooUrl, rooParam).then((res) => {
               if (res.success) {
                  let arr = res.records
                  if (!arr || arr.length <= 0) {
                     this.dEditRoomNoOpt = []
                     return
                  }
                  let tempArr = []
                  let tempObj = {}
                  for (var i = 0; i < arr.length; i++) {
                     tempObj.value = arr[i].id
                     tempObj.label = arr[i].roomNo
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.dEditRoomNoOpt = tempArr
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 获取设备--触发设备(新建场景)
         get_tigger_add (triggerData,targetData,index) {
            let devUrl = urlObj.getDeviceRoomId
            if (index) {
               devUrl = urlObj.getEditDeviceRoomId
            }
            let devParam = {
               roomId: this.roomVal,
               typeCode: 'HOGWZB0100010001',
               typeContain: 'NOT_CONTAIN',
            }
            if (index) {
               devParam.sceneId = this.deviceTypeId
            }
            this.$axios.post(devUrl, devParam).then((res) => {
               if (res.success) {
                  let arr = res.records
                  let tempArr = []
                  let triggerArr = triggerData ? triggerData:[]
                  let targetArr = targetData ? targetData:[]
                  let tempArrId = []
                  let tempObj = {}
                  for (let i = 0; i < arr.length; i++) {
                     tempObj.index = i
                     tempObj.deviceId = arr[i].id
                     tempObj.deviceIndex = arr[i].indexs
                     tempObj.alias = arr[i].alias
                     tempObj.switchAlias = arr[i].switchAlias
                     tempObj.location = arr[i].location
                     tempObj.deviceName = arr[i].deviceName
                     tempObj.versionsName = arr[i].versionsName
                     tempObj.sceneAction = this.sceneAction
                     tempObj.auto = 'TURNON'
                     tempArr.push(tempObj)
                     tempObj = {}
                  }
                  this.executeData = tempArr // 设备列表
               }
            }).catch(error => {
               console.log(error)
            })
         },
         // 添加设备
         addDevice (index) {
            if (this.deviceChange.length <= 0) {
               if (index === 1) {
                  this.$alert(this.sel_trigger_device, '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
               if (index === 2) {
                  this.$alert(this.sel_execute_device, '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
               }
               return
            }
            let deviceChange = JSON.parse(JSON.stringify(this.deviceChange))
            if (!this.addShow) {
               let delUrl = urlObj.updateSceneConfig
               let delParam = {
                  id: this.deviceTypeId, // 场景ID
                  sceneDevices: ''
               }
               if (index === 1) {
                  delParam.sceneDevices = JSON.stringify(this.filterScene(this.sceneAction,deviceChange,'TRIGGER'))
               }
               if (index === 2) {
                  delParam.sceneDevices = JSON.stringify(this.filterScene(this.sceneAction,deviceChange,'TARGET'))
               }
               this.$axios.post(delUrl, delParam).then((res) => {
                  if (res.success) {
                     // console.log(res)
                     this.$message({
                        showClose: true,
                        message: this.add_success,
                        type: 'success'
                     })
                     this.scene_info(this.deviceTypeId) // 场景信息
                     this.deviceChange = []
                  }
               }).catch(error => {
                  console.log(error)
               })
               return
            }
            if (index === 1) {
               // let triggerData = JSON.parse(JSON.stringify(this.triggerData))
               let deviceData = this.triggerData.concat(deviceChange)
               this.triggerData = deviceData
               // console.log(this.triggerData)
            }
            if (index === 2) {
               let deviceData = this.targetData.concat(deviceChange)
               this.targetData = deviceData
            }
            let executeData = JSON.parse(JSON.stringify(this.executeData))
            deviceChange.forEach(devitem => {
               let findindex = executeData.findIndex(item => item.index === devitem.index)
               if (findindex != -1) {
                  executeData.splice(findindex,1)
               }
            })
            this.executeData = executeData
            this.deviceChange = []
         },
         // 删除设备
         remove (scope,index) {
            // console.log(scope.row)
            if (!this.addShow) {
               let delUrl = urlObj.deleteSceneDeviceById
               let delParam = {
                  id: scope.row.id // 场景ID
               }
               this.$axios.get(delUrl, delParam).then((res) => {
                  if (res.success) {
                     // console.log(res)
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.scene_info(this.deviceTypeId) // 场景信息
                  }
               }).catch(error => {
                  console.log(error)
               })
               return
            }
            if (index === 1) {
               this.triggerData.splice(scope.$index,1)
            }
            if (index === 2) {
               this.targetData.splice(scope.$index,1)
            }
            if (scope.row.index) {
               this.executeData.splice(scope.row.index,0,scope.row)
            } else {
               this.executeData.push(scope.row)
            }
         },
         // 保存场景
         add_scenes () {
            if (!this.dSceneModel) {
               this.$alert(this.scene_model_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (this.dRemarkVal) {
               if (this.dRemarkVal.length >= 300) {
                  this.$alert(this.remark_len_no_300, '', {
                     confirmButtonText: this.confirm,
                     type: 'error'
                  })
                  return
               }
            }
            if (!this.triggerData || this.triggerData.length <= 0) {
               this.$alert(this.trigger_device_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.targetData || this.targetData.length <= 0) {
               this.$alert(this.execute_device_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let addUrl = urlObj.addSceneConfig
            let addParam = new FormData()
            if (this.file){
               addParam.append('file', this.file)            // 文件
               addParam.append('module', 'homedevice') // 所在模块
               addParam.append('menu', 'scene')        // 所属菜单
               addParam.append('func', 'image')        // 图片功能
               addParam.append('category', 'image')    // 图片分类
               addParam.append('isThumb', true)        // 是否开启缩略图
            }
            addParam.append('hotelId', this.hotelVal)  // 酒店id
            addParam.append('sceneName', this.dSceneModel)  // 场景模式名称
            addParam.append('roomId', this.roomVal)  // 房间id
            addParam.append('sceneAction', this.sceneAction) // 总的操作状态
            addParam.append('state', this.dSwitchVal)    // 是否生效
            if (!this.addShow) {
               addUrl = urlObj.updateSceneConfig
               addParam.append('id', this.deviceTypeId) // 场景id
               addParam.delete('hotelId')
            } else {
               if (this.appletsVal && this.voiceVal) {
                  addParam.append('sceneSyncType', 'WECHAT_VOICE')
               } else if (this.appletsVal) {
                  addParam.append('sceneSyncType', 'WECHAT')
               } else if (this.voiceVal) {
                  addParam.append('sceneSyncType', 'VOICE')
               }
               addParam.append('triggerDevices', JSON.stringify(this.filterScene(this.sceneAction,this.triggerData,'TRIGGER')))
               addParam.append('targetDevices', JSON.stringify(this.filterScene(this.sceneAction,this.targetData,'TARGET')))
            }
            this.$axios.post(addUrl, addParam, 'file').then((res) => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.addShow?this.add_success:this.update_success,
                     type: 'success'
                  })
                  this.$router.push('/hotel_scenes_config')
               }
            }).catch(error => {
               console.log(error)
            })
         }
      }
   }
</script>
<style scoped lang="scss">
    .cont{
        min-width: 1120px;
        .box-span{
            vertical-align: middle;
            margin-left: 10px;
        }
        .row-buttom{
            text-align: center;
            padding: 20px;
            &.row-padding{
                text-align: right;
                padding-right: 110px;
            }
        }

        /deep/.el-input__inner{
            line-height: 30px;
            height: 30px;
        }
    }
    .el-row{
        line-height: 30px;
    }
    .scene-top{
        display: flex;
        justify-content: flex-start;
        .scene-box{
            padding: 5px;
            border: 1px solid #00c1de;
            background: #e6f9fc;
            border-radius: 3px;
        }
    }
    .det-box{
        /* border: 1px solid red; */
        background: white;
        padding: 10px 20px;
        // margin-bottom: 15px;
    }
    .det-box::after{
        content: "";
        display: block;
        clear: both;
    }
    .det-box > ul{
        list-style: none;
        overflow: hidden;
    }
    .det-box > ul > li{
        float: left;
        width: 33.3%;
        height: 40px;
        margin-bottom: 20px;
        font-size: 14px;
        white-space: nowrap;
        &.active{
            width: 66.6%;
        }
    }
    .det-box > ul > li > span:first-child{
        display: inline-block;
        min-width: 140px;
        text-align: right;
        margin-bottom: 10px;
    }
    .remarks-span{
        span{
            display: inline-block;
            min-width: 140px;
            text-align: right;
            vertical-align: top;
        }
        .el-textarea{
            width: 77%;
        }
        /deep/ textarea{
            min-height: 110px!important;
        }
    }
    @media (max-width:1700px){
        .remarks-span{
            .el-textarea{
                width: 81%;
            }
        }
    }
    @media (max-width:1300px){
        .remarks-span{
            .el-textarea{
                width: 84%;
            }
        }
    }
    // @media screen and (min-width:1024px) and (max-width:1366px){

    // }
    // .ech-box{
    //   border: 1px solid red;
    //   margin-top: 15px;
    // }
    .ech-item{
        height: 300px;
        /* border: 1px solid red; */
        background: white;
        border-radius: 10px;
        position: relative;
        padding-bottom: 30px;
        padding-right: 30px;
    }
    .ech-item > .el-link{
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
    }
    #status-ech{
        height: 100%;
        width: 100%;
    }
    .txt-box{
        position: absolute;
        top: 46px;
        left: 12px;
        line-height: 110px;
    }
    .hour-box{
        position: absolute;
        right: 10px;
        bottom: 50px;
    }
    .execute{
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        padding: 0 5%;
        &>div{
            width: 48%;
        }
        .execute-room{
            display: flex;
            flex-direction: column;
        }
        .top-text{
            font-size: 20px;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            border: 1px solid #EBEEF5;
            // border-bottom: none;
        }
        .execute-tag{
            position: relative;
            .arrow{
                position: absolute;
                width: 30px;
                height: 30px;
                line-height: 30px;
                font-size: 30px;
                top: 50%;
                margin-top: -15px;
                left: -6%;
                cursor: pointer;
            }
            /deep/ {
                .el-radio{
                    margin-right: 10px;
                }
            }
        }
    }
</style>
